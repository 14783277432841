<template>
  <v-container fluid>
    <div class="d-flex mb-4">
      <h1 class="text-h5 font-weight-bold">Vouchers</h1>

      <v-spacer/>
    </div>

    <FilterInput @input="getData" :dataFilters="dataFilters"/>

    <section class="py-4">
      <v-card class="mb-4" outlined>
        <v-data-table
            class="surface"
            :headers="headers"
            :items="vouchers"
            hide-default-footer
        >

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
                class="rounded-lg mr-2"
                :color="item.used == true ? 'green': 'grey'"
                :disable="item.used == true"
                icon
                small
                @click="setUse(item.code,item.used)"
            >
              <v-icon>mdi-check-circle</v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-card>

      <!-- pagination -->
      <v-pagination
          v-model="pagination.page"
          color="primary"
          :length="pagination.pageCount"
          :total-visible="10"
          circle
          @input="getData()"
      />
    </section>

    <loader-hover v-if="loading"/>
  </v-container>
</template>

<script>
import {getVouchers, updateVoucher} from "@/services/vouchers";
import {displayAlert, formatDate} from "@/utils";
import FilterInput from "@/components/filters/FilterInput";

export default {
  watch: {
    $route(to, from) {
      this.getData();
    }
  },

  data() {
    return {
      loading: false,
      interval: null,
      dataFilters: [
        {
          "identifier": "code",
          "title": "Código",
          "hint": "Contém",
          "type": 0
        },
        {
          "identifier": "name",
          "title": "Voucher",
          "items": [
            {"label": "2h Game Center", "value": 'GAME2H'},
            {"label": "50% Barbearia", "value": 'BARBER50'},
          ],
          "type": 4
        },
        {
          "identifier": "used",
          "title": "Aplicado",
          "items": [
            {"label": "Aplicado", "value": true},
            {"label": "Não aplicado", "value": false}
          ],
          "type": 4
        }
      ],
      headers: [
        {text: "Código", value: "code", sortable: false},
        {text: "Voucher", value: "name", sortable: false},
        {text: "Válido até", value: "untilDate", sortable: false},
        {text: "Utilizado em", value: "finishedAt", sortable: false},
        {text: "ID User", value: "user", sortable: false},
        {text: "Descrição", value: "description", sortable: false},
        {text: "Aplicar", value: "actions", sortable: false},
      ],
      pagination: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
      },
      vouchers: [],
    };
  },

  components: {
    FilterInput,
  },

  beforeMount() {
    this.getData();

    this.interval = setInterval(() => {
      this.getData();
    }, 5000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    async getData() {
      try {
        const payload = {
          page: this.pagination.page - 1,
          size: this.pagination.itemsPerPage,
          ...this.$route.query,
        };

        await getVouchers(payload).then((res) => {
          this.vouchers = res.content;
          this.pagination.pageCount = res.totalPages;
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    async setUse(value, used) {
      try {
        this.loading = true;

        const payload = {
          code: value,
          used: !used
        };

        await updateVoucher(payload).then((res) => {
          this.displayAlert("Voucher aplicado com sucesso");
          this.getData();
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },

    displayAlert,

    formatDate,
  },
};
</script>

<style>
</style>